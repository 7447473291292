import { Component } from 'react';
import { connect } from 'react-redux';
import Discover from '../common/redux/actions/DiscoverActions';
import { compose, withState } from 'react-recompose';
import { DatasetQueries } from '../common/graphql/index';
import { graphql } from '@apollo/client/react/hoc';
import URLs from '../common/Urls';
import { DatasetListPanel } from '../datasets/Datasets';
import { messages } from '../i18n';
import { FullScreenPortal } from '../common/widgets/dialogs/full-screen-portal';
import { ButtonContainer, InvisibleButton } from '../ui';

class dialogFunc extends Component {
  componentDidUpdate() {
    const { props } = this;
    if (props.datasets.length === 1) {
      props.onSelected(props.datasets[0]);
    }
  }

  render() {
    const { props } = this;
    const header = (
      <div className='select-viz-header'>
        {messages.selectVizDatasource.header}
      </div>
    );
    return (
      <FullScreenPortal
        show
        titlePanel={header}
        doCancel={props.doCancel}
        className={'select-datasource-dialog'}
        buttonGroup={
          <ButtonContainer
            style={{
              alignSelf: 'flex-end',
              marginBottom: '0.8rem',
              marginRight: '1rem',
            }}
          >
            <InvisibleButton onClick={props.doCancel}>Cancel</InvisibleButton>
          </ButtonContainer>
        }
      >
        <DatasetListPanel
          key='datasetList'
          newVisualization={datasetId => props.onSelected(datasetId)}
          list={props.datasets}
          getContentForDataset={props.getContentForDataset}
          currentUser={props.currentUser}
          search={props.search}
          setSearch={search => props.setSearch(search)}
          selectionMode
        />
      </FullScreenPortal>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    doCancel: () => {
      URLs.goToLastOrHome();
    },
    onSelected: dataset => {
      dispatch(Discover.newVisualization({ dataset }));
      dispatch(Discover.setSaveActive(false));
    },
  };
};

const DatasetQuery = graphql(DatasetQueries.DatasetQuery, {
  props: store => {
    const datasets =
      store.data && store.data.datasets ? [...store.data.datasets] : [];
    return { datasets };
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('search', 'setSearch', ''),
  DatasetQuery,
)(dialogFunc);
