import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDiscoverTheme } from '../../common/emotion';

export const useTabsStyles = () => {
  const {
    colors: { ActiveTabIndicator, LightBlue, SemiboldFontWeight, ContentText },
  } = useDiscoverTheme();

  return {
    tabsSx: {
      minHeight: '32px',

      '& .MuiTabs-indicator': {
        height: '4px',
        background: ActiveTabIndicator,
      },
    },
    tabSx: {
      textTransform: 'none',
      fontSize: '0.6875rem',
      lineHeight: '1.2',
      fontWeight: SemiboldFontWeight,
      minHeight: '32px',
      paddingTop: '9px',
      paddingBottom: '9px',
      width: '200px',
      color: ContentText,

      '&:hover': {
        background: LightBlue,
      },

      '&.Mui-selected': {
        color: ActiveTabIndicator,
      },
    },
  };
};

export const ContentContainer = styled.div(() => {
  const {
    colors: { TableHeaderBackground },
  } = useDiscoverTheme();

  return css`
    padding: 20px;
    background: ${TableHeaderBackground};
  `;
});

export const ContentContainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Root = styled.div`
  height: 100%;
`;

export const TabsWrapper = styled.div(() => {
  const {
    darkMode,
    colors: { PivotWhite, TabBackground },
  } = useDiscoverTheme();

  return css`
    background-color: ${darkMode ? TabBackground : PivotWhite};
  `;
});
