import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Tab from '@mui/material/Tab';
import { VizTabNavHeight, useDiscoverTheme } from '../../common/emotion';

export const CloseDiscovery = styled.div`
  opacity: 0;
  position: absolute;
  right: 8px;
`;

export const TabStyled = styled(Tab)(() => {
  const {
    colors: {
      LightBlue,
      RegularFontWeight,
      SemiboldFontWeight,
      TabTextColor,
      ActiveTabTextColor,
    },
  } = useDiscoverTheme();

  return css`
    text-transform: none;
    font-size: 0.885rem;
    line-height: 1.2;
    font-weight: ${RegularFontWeight};
    min-height: 40px;
    padding: 11px 24px;
    color: ${TabTextColor};
    min-width: 0;
    max-width: 200px;
    &:hover {
      background: ${LightBlue};
      ${CloseDiscovery} {
        opacity: 1;
      }
    }
    &.Mui-selected {
      color: ${ActiveTabTextColor};
      font-weight: ${SemiboldFontWeight};
      @media (max-width: 808px) {
        ${CloseDiscovery} {
          opacity: 1;
        }
      }
    }
  `;
});

export const DiscoveryDirtyIndicator = styled.div`
  position: absolute;
  left: 8px;
`;

export const AddDiscovery = styled.button(() => {
  const {
    colors: { TabBackground },
  } = useDiscoverTheme();

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${TabBackground};
    height: ${VizTabNavHeight}px;
    width: 56px;
    padding: 0 20px;
    border: none;
  `;
});
