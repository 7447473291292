import { TooltipText } from './truncated-tooltip.styles';
import { Tooltip } from '../tooltip';
import { useEffect, useRef, useState } from 'react';

export const TruncatedTooltip = props => {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, clientWidth } = textRef.current;
      setIsTruncated(scrollWidth > clientWidth);
    }
  }, [textRef]);

  return (
    <Tooltip disableHoverListener={!isTruncated} {...props}>
      <TooltipText ref={textRef}>{props.title}</TooltipText>
    </Tooltip>
  );
};
