import { SyntheticEvent, useState } from 'react';
import {
  ButtonContainer,
  InvisibleButton,
  PrimaryButton,
  SecondaryButton,
} from '../../ui';
import { filter } from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FlexRight, FlexSpaced } from '../../components/ui';
import { TextSearchField } from '../../components/ui/form';
import SearchCollection from '../../common/SearchCollection';
import { messages } from '../../i18n';
import { AdministrationTable } from './administration-table';
import { FullScreenPortal } from '../../common/widgets/dialogs/full-screen-portal';
import {
  ContentContainer,
  ContentContainerRoot,
  Root,
  TabsWrapper,
  useTabsStyles,
} from './administration.styles';
import { useAdministration } from './administration.hook';

const PANEL_ACTIVE = 'Active';

export const Administration = () => {
  const [search, setSearch] = useState('');
  const [showTab, setShowTab] = useState(1);
  const {
    isIdmAdministrator,
    idmAdminUrl,
    users,
    saving,
    onRoleChange,
    doCancel,
    save,
    saveAndClose,
  } = useAdministration();

  const { tabsSx, tabSx } = useTabsStyles();

  const navigateToPanel = (_event: SyntheticEvent, panelId: number) => {
    setShowTab(panelId);
  };

  const goToCloudSettings = () => {
    const win = window.open(idmAdminUrl, '_blank');
    win.focus();
  };

  const options = {
    keys: ['fullName', 'title', 'email'],
  };
  const searcher = new SearchCollection(users, options);
  const list = !search || search === '' ? users : searcher.search(search);

  const current = filter(list, { status: PANEL_ACTIVE });

  let buttonsOrManageUsers = null;
  // if this is an IDM Instance, and the user is also an IdmAdmin show the manage users buttons
  // if the user is not an IDM admin then do not show any buttons
  if (isIdmAdministrator) {
    buttonsOrManageUsers = (
      <PrimaryButton
        key={`admin-cloud-settings-button`}
        className=''
        onClick={() => goToCloudSettings()}
        sx={{ marginRight: '0.5rem' }}
      >
        {messages.formatString(
          messages.administration.manageUsersInCloudSettingsButton,
          messages.nonTranslated.sugarIdentity,
        )}
      </PrimaryButton>
    );
  }

  const renderPanel = () => {
    switch (showTab) {
      case 1: {
        return (
          <AdministrationTable
            {...{
              list: current,
              type: PANEL_ACTIVE,
              onRoleChange,
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const isSaveEnabled = users.some(user => user.isUpdated);

  return (
    <FullScreenPortal
      className='admin-dialog'
      titlePanel={messages.administration.manageUsersTitle}
      buttonGroup={
        <ButtonContainer>
          <InvisibleButton
            className='cancel-button'
            onClick={doCancel}
            disabled={saving}
          >
            {messages.cancel}
          </InvisibleButton>
          <SecondaryButton onClick={save} disabled={!isSaveEnabled || saving}>
            {saving
              ? messages.editDatasetPanel.applying
              : messages.editDatasetPanel.applyChanges}
          </SecondaryButton>
          <PrimaryButton
            onClick={saveAndClose}
            disabled={!isSaveEnabled || saving}
          >
            {saving
              ? messages.editDatasetPanel.updating
              : messages.editDatasetPanel.saveChangesAndExit}
          </PrimaryButton>
        </ButtonContainer>
      }
    >
      <Root className='Administration'>
        <ContentContainerRoot>
          <ContentContainer>
            <FlexSpaced>
              <TextSearchField
                placeholder={messages.administration.searchUsersPlaceholder}
                onChange={setSearch}
                role='search'
              />
              <FlexRight>{buttonsOrManageUsers}</FlexRight>
            </FlexSpaced>
          </ContentContainer>
          <TabsWrapper>
            <Tabs
              value={showTab}
              onChange={navigateToPanel}
              aria-label='dataset tabs'
              sx={tabsSx}
            >
              <Tab
                label={messages.formatString(
                  messages.administration.currentUsersTab,
                  current.length,
                )}
                value={1}
                disabled={current.length <= 0}
                sx={tabSx}
              />
            </Tabs>
          </TabsWrapper>
          {renderPanel()}
        </ContentContainerRoot>
      </Root>
    </FullScreenPortal>
  );
};
