import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  isIdmAdministrator as isIdmAdministratorSelector,
  idmAdminUrl as idmAdminUrlSelector,
} from '../../common/redux/selectors/AccountSelectors';
import AdminQueries from '../../common/graphql/AdminQueries';
import { useAccountMutation } from '../../account/graphql';
import URLs from '../../common/Urls';
import { useToastProvider } from '../../components/toast/toast-launcher';
import { omit } from 'lodash';
import { IUserInfo } from '../../account/interfaces/user-account.interface';

export const useAdministration = () => {
  const [users, setUsers] = useState([]);
  const { isIdmAdministrator, idmAdminUrl } = useSelector((state: any) => ({
    isIdmAdministrator: isIdmAdministratorSelector(state?.account),
    idmAdminUrl: idmAdminUrlSelector(state?.account),
  }));
  const { data } = useQuery(AdminQueries.GetUsersQuery);
  const { updateUser, saving } = useAccountMutation(false);
  const { showToast } = useToastProvider();

  useEffect(() => {
    if (data?.getUsers) {
      setUsers(data.getUsers);
    }
  }, [data]);

  const onRoleChange = (rowData, newRole) => {
    const updatedUser = {
      ...rowData,
      roles: [newRole],
      isUpdated: true,
    };
    setUsers(prev =>
      prev.map(user => (user.id === updatedUser.id ? updatedUser : user)),
    );
  };

  const resetUsers = () => {
    setUsers(prev => prev.map(user => ({ ...user, isUpdated: false })));
  };

  const doCancel = () => {
    resetUsers();
    URLs.goToLastOrHome();
  };

  const save = async () => {
    const updatedUsers = users.filter(user => user.isUpdated);
    try {
      await Promise.all(
        updatedUsers.map(user =>
          updateUser(omit(user, 'isUpdated') as IUserInfo),
        ),
      );
      resetUsers();
    } catch (e) {
      showToast({ type: 'error', label: e.message });
    }
  };

  const saveAndClose = () => {
    save();
    doCancel();
  };

  return {
    isIdmAdministrator,
    idmAdminUrl,
    users,
    saving,
    onRoleChange,
    doCancel,
    save,
    saveAndClose,
  };
};
