import { useCallback } from 'react';
import _ from 'lodash';
import PromptDialog from '../../common/widgets/dialogs/PromptDialog';
import CopyQuerySqlButton from '../../views/ReportDetail/CopyQuerySqlButton';
import {
  usePromptText,
  useQueryError,
  useRetryQuery,
} from './query-error.hook';
import {
  ErrorVariablesContainer,
  StyledCopySqlButton,
  PreFormattedVariables,
} from './query-error-dialog.styles';
import { useIsAdvancedMode } from '../../common/redux/selectors/main-selector-hooks';
import {
  useIgnoreErrorDiscoverAction,
  useLiveQueryOptionGetterSetter,
  useUndoDiscoveryAction,
} from '../../common/redux/actions/discover-actions.hook';
import { useSelector } from 'react-redux';
import { HelpBlock } from '../../views/VizSaveDialog/ui';
import { IErrorMap } from './query-error.interfaces';
import { Box } from '@mui/system';

const ERROR_MAPPINGS: IErrorMap[] = [
  {
    pattern: /(TooManyRetriesException|Network Error:.*504|Network error:.*502)/,
    friendlyErrorMessage: 'queryError.maxTimeError2',
  },
];

export const QueryErrorDialog = ({ vizId }: { vizId?: string }) => {
  const isAdvancedMode = useIsAdvancedMode();
  const isDashletMode = useSelector(
    (state: any) => state.dashlet.isDashletMode,
  );

  const undo = useUndoDiscoveryAction({ vizId });
  const ignoreError = useIgnoreErrorDiscoverAction({ vizId });

  const { disableUseLiveQuery, useLiveQuery } = useLiveQueryOptionGetterSetter({
    vizId,
  });
  const { variables, retryQuery } = useRetryQuery({ vizId });
  const { errorMessage, queryError, hasUndoItems } = useQueryError({
    vizId,
    errorMappings: ERROR_MAPPINGS,
  });
  const show = !_.isNil(queryError) && !queryError?.ignore;

  const onHide = useCallback(() => {
    // if there are items in the undo stack, undo the last action
    if (hasUndoItems) {
      undo();
    } else {
      ignoreError();
    }
  }, [hasUndoItems, ignoreError, undo]);

  const originalErrorMessage = _.get(queryError, 'error.message', '');
  const onNo = useCallback(() => {
    disableUseLiveQuery();
    ignoreError();
  }, [disableUseLiveQuery, ignoreError]);

  const { cancelText, noText, yesText } = usePromptText({
    hasUndoItems,
    useLiveQuery,
  });

  return (
    <PromptDialog
      show={show}
      doCancel={onHide}
      cancelText={cancelText}
      cancelDisabled={false}
      doNo={useLiveQuery ? onNo : undefined}
      noText={noText}
      noDisabled={!useLiveQuery}
      doYes={retryQuery}
      yesText={yesText}
      hideAllButtons={isDashletMode}
      autoFocus={!isDashletMode}
      enforceFocus={!isDashletMode}
    >
      <HelpBlock className='text-center'>
        <p>{errorMessage}</p>
        {isAdvancedMode && errorMessage !== originalErrorMessage && (
          <p>
            <code>{originalErrorMessage}</code>
          </p>
        )}
        <br />
      </HelpBlock>
      {isAdvancedMode && (
        <ErrorVariablesContainer>
          <StyledCopySqlButton>
            <CopyQuerySqlButton />
          </StyledCopySqlButton>
          <br />
          <Box>
            <Box>
              <PreFormattedVariables>
                {JSON.stringify(variables, undefined, 2)}
              </PreFormattedVariables>
            </Box>
          </Box>
          <br />
        </ErrorVariablesContainer>
      )}
    </PromptDialog>
  );
};
