import styled from '@emotion/styled';
import ProfileImageComponent from '../../../components/ProfileImage';

export const AdministrationTableWrapper = styled.div`
  flex: 1;
  display: flex;

  .UITable .ReactVirtualized__Table__headerRow {
    border-top: none;
  }
`;

export const ProfileImage = styled(ProfileImageComponent)`
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  border-radius: 20px;
  margin-right: 8px;
  margin-right: 8px;
`;
